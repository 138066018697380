/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// core components
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import Button from "../CustomButtons/Button";

import headerLinksStyle from "../../assets/jss/material-kit-react/components/headerLinksStyle";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faTrophy, faUserFriends, faUsers, faHome, faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons";
import { faChild } from "@fortawesome/free-solid-svg-icons";
import { Hidden } from "@material-ui/core";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      
      <Hidden mdUp>
        <ListItem className={classes.listItem}>
          <Link to="/" className={classes.navLink}>
            <FontAwesomeIcon className={classes.icons} icon={faHome} /> LoB - Welkom
          </Link>
        </ListItem>
      </Hidden>
      
      <ListItem className={classes.listItem}>
        <Link to="/jeugd" className={classes.navLink}>
          <FontAwesomeIcon className={classes.icons} icon={faChild} /> Jeugd
        </Link>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Volwassenen"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={<FontAwesomeIcon icon={faUserFriends} />}
          dropdownList={[
            <Link to="/recreanten" className={classes.dropdownLink}>
              <FontAwesomeIcon className={classes.icons} icon={faUmbrellaBeach} /> Recreanten
            </Link>,
            <Link to="/competitie" className={classes.dropdownLink}>
              <FontAwesomeIcon className={classes.icons} icon={faTrophy} /> Competitie
            </Link>,
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Club"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={<FontAwesomeIcon icon={faUsers} />}
          dropdownList={[
            <Link to="/over-ons" className={classes.dropdownLink}>
              Over ons
            </Link>,
            <Link to="/bestuur" className={classes.dropdownLink}>
              Bestuur
            </Link>,
            <Link to="/kalender" className={classes.dropdownLink}>
              Kalender
            </Link>,
            <Link to="/inschrijven" className={classes.dropdownLink}>
              Lid worden
            </Link>,
            <Link to="/winkel" className={classes.dropdownLink}>
              LoB-Winkel
            </Link>,
            <Link to="/trooper" className={classes.dropdownLink}>
              Trooper
            </Link>,
            <Link to="/faq" className={classes.dropdownLink}>
              FAQ - Vragen
            </Link>,
            <a
              href="https://linktr.ee/londerzeelbadminton?ref=lobwww"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.dropdownLink}
            >
              Evenementen
            </a>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="facebook-tooltip"
          title="Follow us on Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/LonderzeelBadminton?ref=lobwww"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.navLink}
          >
            <FontAwesomeIcon className={classes.socialIcons} icon={faFacebook} />
            <Hidden mdUp>&nbsp;Facebook</Hidden>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on Instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/londerzeelbadminton?ref=lobwww"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.navLink}
          >
            <FontAwesomeIcon className={classes.socialIcons} icon={faInstagram} />
            <Hidden mdUp>&nbsp;Instagram</Hidden>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="Youtube-tooltip"
          title="Follow us on Youtube"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.youtube.com/channel/UCsfRovP2U8_P5vYN-rnU-2Q?ref=lobwww"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.navLink}
          >
            <FontAwesomeIcon className={classes.socialIcons} icon={faYoutube} />
            <Hidden mdUp>&nbsp;Youtube</Hidden>
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
