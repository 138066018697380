/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
// nodejs library that concatenates classes
import classNames from "classnames";
import { List, ListItem, withStyles, Grid } from "@material-ui/core";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import logoConceptImg from '../../assets/img/logoconcept-sq.png';
const logoTrooper = '/static/trooperlogo.png';

import footerStyle from "../../assets/jss/material-kit-react/components/footerStyle";
import { Link } from "react-router-dom";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IFooter {
  classes?: any;
  whiteFont?: boolean;
}

function Footer({ ...props }: IFooter) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://www.facebook.com/hetconceptsteenhuffel"
                  className={classes.block}
                  target="_blank"
                >
                  <img style={{ height: 80, marginRight: 8 }} src={logoConceptImg} />
                  't Concept
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://www.trooper.be/nl/trooperverenigingen/londerzeelbadminton"
                  className={classes.block}
                  target="_blank"
                >
                  <img style={{ height: 80, marginRight: 8 }} src={logoTrooper} />
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href={`https://www.facebook.com/LonderzeelBadminton/`}
                  className={classes.block}
                  target="_blank"
                >
                  Follow us on &nbsp;
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href={`https://www.instagram.com/londerzeelbadminton?ref=lobwww`}
                  className={classes.block}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Watch us on &nbsp;
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href={`https://www.youtube.com/channel/UCsfRovP2U8_P5vYN-rnU-2Q`}
                  className={classes.block}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube &nbsp;
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/contact" className={classes.block}>Contacteer ons</Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/privacy" className={classes.block}>Privacy</Link>
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            &copy; {new Date().getUTCFullYear()}
            &nbsp;-&nbsp;
            Made with <Favorite className={classes.icon} /> by&nbsp;
            <a
              href="https://bennit.be?ref=lob"
              className={aClasses}
              target="_blank"
            >
              Bennit
            </a>&nbsp;.
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}

export default withStyles(footerStyle)(Footer);
