// import 'typeface-roboto';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch, useParams } from "react-router-dom";

import "./assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import HomePage from "./views/HomePage/HomePage";

import lobTheme from './components/LobPage/lobTheme';
import { MuiThemeProvider } from '@material-ui/core';
import ContactPage from './views/ContactPage/ContactPage';
import CalendarPage from './views/CalendarPage/CalendarPage';
import ScrollToTop from './ScrollToTop';
import CMSLobPage from './components/CMSLobPage/CMSLobPage';
import TeamPage from './views/TeamPage/TeamPage';

const CMSLobPageHelper = () => {
  const { slug } = useParams<{ slug: string }>();
  if (!slug) {
    console.error('slug is mandatory');
    return null;
  }
  return <CMSLobPage slug={slug} />;
}

const InschrijvingRedirect: React.FC = () => {
  window.location.href = 'https://app.twizzit.com/go/inschrijving-LOB-24-25';
  return null;
};

ReactDOM.render(
  <MuiThemeProvider theme={lobTheme}>
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/kalender" component={CalendarPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/inschrijven" component={InschrijvingRedirect} />
          <Route path="/bestuur">
            <CMSLobPage slug="bestuur">
              <TeamPage />
            </CMSLobPage>
          </Route>

          <Route path="/:slug"><CMSLobPageHelper /></Route>
          <Route path="/" component={HomePage} />
        </Switch>
      </ScrollToTop>
    </Router>  
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
