import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/styles";
import classNames from 'classnames';

import picture1 from './1.jpg';
import picture2 from './2.jpg';
import picture3 from './3.jpg';

// core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

import scheduleSectionStyle from "./scheduleSectionStyle";
import { TFN } from "../../../components/CMSLobPage/useT";
import T from "../../../components/CMSLobPage/T";


interface IScheduleSection { t: TFN }
type Props = IScheduleSection & WithStyles<typeof scheduleSectionStyle>;
const ScheduleSection: React.FC<Props> = ({ classes, t }) => {
  const imgClasses = classNames(classes.image, classes.imgCard, classes.imgRaised, classes.imgGallery);
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}><T t={t} k="section2-title" /></h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <h3 className={classes.title}><T t={t} k="section2-img1-title" /></h3>
            <div className={imgClasses} style={{backgroundImage: `url(${picture1})`}} />
            <h5 className={classes.description}>
              <T t={t} k="section2-img1-description" />
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <h3 className={classes.title}><T t={t} k="section2-img2-title" /></h3>
            <div className={imgClasses} style={{backgroundImage: `url(${picture2})`}} />
            <h5 className={classes.description}>
              <T t={t} k="section2-img2-description" />
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <h3 className={classes.title}><T t={t} k="section2-img3-title" /></h3>
            <div className={imgClasses} style={{backgroundImage: `url(${picture3})`}} />
            <h5 className={classes.description}>
              <T t={t} k="section2-img3-description" />
            </h5>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}


export default withStyles(scheduleSectionStyle)(ScheduleSection);
