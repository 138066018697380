import React from 'react';
import { Grid } from '@material-ui/core';
import { faShuttlecock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Loading.scss';

const Loading: React.FC = () => (
    <div className='Loading'>
        <Grid container justifyContent='center' alignItems='center' style={{width: '100vw', height: '100vh' }}>
            <Grid item>
                <FontAwesomeIcon icon={faShuttlecock} color="success" size="4x" className='pulse'/>
            </Grid>
        </Grid>
    </div>
)

export default Loading;
