import React from 'react';

import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/styles";

// core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

import productStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/productStyle";
import { Grid } from '@material-ui/core';
import { TFN } from '../../../components/CMSLobPage/useT';
import T from '../../../components/CMSLobPage/T';

interface ISporthalSection {
  t: TFN;
}
type Props = ISporthalSection & WithStyles<typeof productStyle>;
const SporthalSection: React.FC<Props> = ({ classes, t }) => {
  return (
    <div className={classes.section} id="sporthal-section">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            <T t={t} k="section3-title" />
          </h2>
          <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6}>
                  <div className={classes.description}>
                    <T t={t} k="section3-description" />
                  </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                  {/* <div style={{position: 'relative', textAlign: 'right', height: 300, width: 500}}>
                      <div style={{overflow: 'hidden', background: 'none', height: 300, width: 500}}> */}
                          <iframe
                              style={{width: '100%', height: 300}}
                              title="gmaps_delijster"
                              id="gmaps_delijster"
                              src="https://maps.google.com/maps?q=sportoase%20de%20lijster&t=&z=13&ie=UTF8&iwloc=&output=embed"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight={0}
                              marginWidth={0} />
                      {/* </div>
                  </div> */}
              </Grid>
          </Grid>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(productStyle)(SporthalSection);
