import React, { useState } from "react";
import { useLobAPI } from "../../components/CMSLobPage/useLobAPI";
import { Crews } from "../../lib/LobAPI";

function useCrews() {
  const api = useLobAPI();
  const [crews, setCrews] = useState<Crews | null>(null);
  const [error, setError] = useState<boolean>(false);
  React.useEffect(() => {
    api.getCrews()
      .then(setCrews)
      .catch(() => setError(true));
  }, [api]);
  return { crews, isLoading: !crews, error };
}

export default useCrews;
