import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

const Warning: React.FC = () => {

  const [isShowing, setIsShowing] = useState(true);
  const handleClose = () => setIsShowing(false);
  return (
    <Dialog open={isShowing} onClose={handleClose}>
      <DialogTitle>Inschrijvingen 2024-2025</DialogTitle>
      <DialogContent>
        {/* <div style={{display: 'flex', alignItems: 'center', width: '100%', paddingBottom: '8px'}}>
          <img style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}} src="/static/wafel.png" alt="heerlijke wafeltjes" />
        </div> */}
        <p>
          Vanaf nu zijn de inschrijvingen voor het nieuwe seizoen 2024-2025 geopend.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>ok</Button>
        <Button href="/inschrijven" variant="contained" color="primary">Meer info</Button>
      </DialogActions>
    </Dialog>
  );
}

export default Warning;
