import React from 'react';
import { TFN } from './useT';
import Markdown from '../Markdown/Markdown';

const T: React.FC<{ t: TFN, k: string }> = ({ t, k: key }) => {
  const { value, type } = t(key);
  if (type === 'markdown') {
    return <Markdown source={value} />;
  }
  return <span>{value}</span>;
}

export default T;
