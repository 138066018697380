import React from "react";

import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/styles";
// import { Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";

// core components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import HeaderLinks from "../../components/Header/HeaderLinks";
import Parallax from "../../components/Parallax/Parallax";

import lobPageStyle from "./lobPageStyle";
import defaultParalax from './defaultParalax.jpg';
import logoBanner from '../../assets/img/logo-banner.png';

import './LobPage.css';

interface ILobPage {
  /** Which image to use for paralax */
  paralax?: string;
  paralaxContent?: JSX.Element;
  header?: {
    height: number;
    color?: string;
  };
  small?: boolean;
}
type Props = ILobPage & WithStyles<typeof lobPageStyle>;
class LobPage extends React.Component<Props> {
  render() {
    const { classes, children, header, paralax, paralaxContent, small } = this.props;
    return (
      <>
        <Header
          brand={
            <Link to="/" className={classes.brand}>
              <img src={logoBanner} className={classes.banner} alt="banner linking to home" />
            </Link>
          }
          color="transparent"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: header?.height || 200,
            color: header?.color || "white",
          }}
        />
        <Parallax
          filter
          image={paralax || defaultParalax}
          small={!!small}
        >
          {paralaxContent}
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {children}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default withStyles(lobPageStyle)(LobPage);
