import { title } from "../../../assets/jss/material-kit-react";
import imageStyles from '../../../assets/jss/material-kit-react/imagesStyles';
import { createStyles } from "@material-ui/core";

const scheduleSectionStyle = createStyles({
  ...imageStyles,
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#999",
    fontWeight: 'bolder',
  },
  image: {
    width: '100%',
    height: 500,
    borderRadius: 40,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
});

export default scheduleSectionStyle;
