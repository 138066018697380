import React from "react";

import LobPage from "../../components/LobPage/LobPage";

import calendarParalax from './calendarParalax.jpg';
import KalenderSection from "../HomePage/KalenderSection/KalenderSection";

interface ICalendarPage { }
type Props = ICalendarPage;

class CalendarPage extends React.Component<Props> {
  render() {
    // const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
      <LobPage paralax={calendarParalax} small={true}>
        <KalenderSection />
      </LobPage>
    );
  }
}

export default CalendarPage;
