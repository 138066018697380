import React from 'react';

import LobPage from '../LobPage/LobPage';
import Markdown from '../Markdown/Markdown';

interface ILobTextPage {
    paralax?: string;
    large?: boolean;
    content: string;
}

type Props = ILobTextPage;

const LobTextPage: React.FC<Props> = ({ paralax, content, large, children }) => (
    // <GridContainer justify="center">
    //  <GridItem xs={12} sm={12} md={6}>
    <LobPage paralax={paralax} small={!large}>
        <Markdown source={content} />
        {children}
    </LobPage>
);

export default LobTextPage;