import React from 'react';
import { useLobAPI } from './useLobAPI';
import { LobPage } from '../../lib/LobAPI';
import LobTextPage from '../LobTextPage/LobTextPage';
import Loading from './Loading';

interface CMSLobPageState {
    error: boolean;
    loading: boolean;
    page: LobPage | null;
}

export interface CMSLobPageProps {
    slug: string;
}

const CMSLobPage: React.FC<CMSLobPageProps> = ({ slug, children }) => {

    const api = useLobAPI();

    const [content, setContent] = React.useState<CMSLobPageState>({
        error: false,
        loading: true,
        page: null,
    });
    
    React.useEffect(() => {
        let unmounting = false;
        setContent({
            error: false,
            loading: true,
            page: null,
        });

        api.getLobPage(slug).then((page) => {
            if(!unmounting) {
                setContent({
                    page,
                    error: false,
                    loading: false,
                });
            }
        }).catch((e) => {
            console.error('CMSLobPage error', e);
            if (!unmounting) {
                setContent({
                    error: true,
                    loading: false,
                    page: null,
                });
            }
        });
        
        return () => unmounting = true;
    }, [slug, api])

    if (content.error) {
        return <>Oeps! Er lijkt iets mis met deze pagina. Contacteer een LOB-bestuurslid indien het probleem blijft.</>;
    }

    if (content.loading) {
        return <Loading />;
    }

    if (!content.page) {
        return (
          <LobTextPage content=''>
            <h3>Oeps! Deze pagina bestaat niet</h3>
          </LobTextPage>
        );
    }

    return (
        <LobTextPage paralax={content.page.parallaxImage.url} large={content.page.useLargeParallax} content={content.page.content}
        >
            {children}
        </LobTextPage>
    );

};

export default CMSLobPage;
