import React from 'react';

import './markdown.scss';
import ReactMarkdown from 'react-markdown';

interface MarkdownProps {
    source: string;
}

type Props = MarkdownProps;

function LinkRenderer(props: { href?: string; children: any }) {
  if (props.href?.startsWith('http')) {
    return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>
  }
  return <a href={props.href}>{props.children}</a>;
}

const Markdown: React.FC<Props> = ({ source }) => (
  <ReactMarkdown
    source={source}
    className="markdown"
    escapeHtml={false}
    renderers={{link: LinkRenderer}}
  />
);

export default Markdown;
