import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import defaultParalax from '../components/LobPage/defaultParalax.jpg';

const DEFAULT_API_URL = 'https://api-eu-central-1.hygraph.com/v2/ckrnwtzur4bdt01xscs0gcp15/master'

const LOB_PAGE_DEFAULTS: LobPage = {
    id: 'default',
    content: 'Deze pagina komt er binnenkort aan!',
    parallaxImage: { url: defaultParalax },
    useLargeParallax: false,
}

export interface LobPage {
    
    id: string;
    content: string;
    useLargeParallax: boolean;
    parallaxImage: {
        url: string;
    };
}

export interface Translation {
    id: string;
    group: string;
    entries: {[key: string]: TranslationEntry};
}

export type TranslationEntry = TextTranslationEntry | MarkdownTranslationEntry;

export interface TextTranslationEntry {
    type: 'text';
    key: string;
    value: string;
}

export interface MarkdownTranslationEntry {
    type: 'markdown';
    key: string;
    value: string;
}

export interface Crews {
  bestuur: Crew[];
  trainers: Crew[];
  wedstrijdleiders: Crew[];
}

export interface Crew {
  name: string;
  function: string | null;
  avatar: string | null;
  email: string | null;
  facebook: string | null;
  phone: string | null;
}

interface LobPageQuery {
    page: {
        id: string;
        content: string | null;
        useLargeParallax: boolean | null;
        parallaxImage: {
            url: string | null;
        } | null;
    } | null;
}

interface TranslationByGroupQuery {
    translation: {
        id: string;
        group: string;
        entries: {
            key: string;
            value: string | null;
            markdownValue: string | null;
        }[] | null;
    }
}

interface CrewsQuery {
  bestuur: CrewFragment[];
  trainers: CrewFragment[];
  wedstrijdleiders: CrewFragment[];
}

interface CrewFragment {
  id: string;
  name: string;
  function: string | null;
  email: string | null;
  facebook: string | null;
  phone: string | null;
  avatar: { url: string | null } | null;
}

class LobAPI {

    private client: ApolloClient<any>;

    constructor(url: string = DEFAULT_API_URL) {
        this.client = new ApolloClient({
            uri: url,
            cache: new InMemoryCache(),
        });
    }

    async getTranslationByGroup(group: string): Promise<Translation | null> {
        const query = gql`
        query TranslationByGroup {
          translation(where: {group: "home"}) {
            id
            group
            entries {
              key
              value
              markdownValue
            }
         }
        }          
        `;

        const variables = { group };
        const res = await this.client.query<TranslationByGroupQuery>({ query, variables }).catch((e) => { console.error(e); return Promise.reject(e); });
        if (res.error) {
            console.error(`getTranslationByGroup("${group}") failed`, res);
            throw new Error(`Unexpected error fetching page "${group}"`);
        }
        if (!res.data.translation) {
            console.warn(`${group} does not exist`);
            return null;
        }
        const {
            id,
            entries,
        } = res.data.translation;
        
        return {
            id,
            group,
            entries: entries.reduce((acc, { key, value, markdownValue }) => {
              acc[key] = {
                type: markdownValue ? 'markdown' : 'text',
                key,
                value: markdownValue ?? value ?? '...',
              }
              return acc;
            }, {}),
        };
    }

    async getLobPage(slug: string): Promise<LobPage | null> {
        const query = gql`
        query LobPage($slug: String!) {
            page(where: {slug: $slug}) {
                id
                content
                useLargeParallax
                parallaxImage {
                    url(transformation: { image: { resize: { width: 640, height: 360 }}})
                }
            }
        }
        `;
        const variables = { slug };
        const res = await this.client.query<LobPageQuery>({ query, variables });
        if (res.error) {
            console.error(`getLobPage("${slug}") failed`, res);
            throw new Error(`Unexpected error fetching page "${slug}"`);
        }
        if (!res.data.page) {
            console.warn(`${slug} does not exist`);
            return null;
        }
        const {
            id,
            content = LOB_PAGE_DEFAULTS.content,
            useLargeParallax = LOB_PAGE_DEFAULTS.useLargeParallax,
            parallaxImage = LOB_PAGE_DEFAULTS.parallaxImage,
        } = res.data.page;
        
        return {
            id,
            content,
            parallaxImage: { url: parallaxImage?.url ?? LOB_PAGE_DEFAULTS.parallaxImage.url },
            useLargeParallax,
        };
    }

    async getCrews(): Promise<Crews> {
      const query = gql`
      query getCrews {
        bestuur: crews(where: {type: bestuur}, orderBy: boost_DESC) { ...crew }
        trainers: crews(where: {type: trainer}, orderBy: boost_DESC) { ...crew }
        wedstrijdleiders: crews(where: {type: wedstrijdleider}, orderBy: boost_DESC) { ...crew }
      }
      
      fragment crew on Crew {
        id
        name
        function
        avatar {
          url(transformation: {image: {resize: {width: 100, height: 100, fit: clip}}})
        }
        email
        facebook
        phone
      }
      `;
      const res = await this.client.query<CrewsQuery>({ query });
      if (res.error) {
          console.error(`getCrews() failed`, res);
          throw new Error(`Unexpected error fetching crews`);
      }
      const mapCrewFragment = (c: CrewFragment): Crew => ({
        name: c.name,
        avatar: c.avatar?.url ?? null,
        email: c.email,
        facebook: c.facebook,
        function: c.function,
        phone: c.phone,
      });
      return {
          bestuur: res.data.bestuur.map(mapCrewFragment),
          trainers: res.data.trainers.map(mapCrewFragment),
          wedstrijdleiders: res.data.wedstrijdleiders.map(mapCrewFragment),
      };
    }
}

export default LobAPI;
