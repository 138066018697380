import { container, title, defaultFont } from "../../assets/jss/material-kit-react";

import imagesStyle from "../../assets/jss/material-kit-react/imagesStyles";
import { createStyles } from "@material-ui/core";

const lobPageStyle = createStyles({
  container,
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: 3,
    paddingBottom: 32,
    minHeight: 300,
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
    textAlign: "left"
  },
  content: {
    marginTop: "-80px"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  brand: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    padding: "8px 16px",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      color: "inherit",
      background: "transparent"
    },
    cursor: 'pointer',
    fontWeight: 600,
  },
  banner: {
    height: 50,
    padding: 4,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 4,
    // 'white',
  },
});

export default lobPageStyle;
