/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
// import Button from "../../components/CustomButtons/Button";

import landingPageStyle from "../../assets/jss/material-kit-react/views/landingPage";

// Sections for this page
import TrainingSection from "./TrainingSection/TrainingSection";
import ContactSection from "./ContactSection/ContactSection";
import SporthalSection from "./SporthalSection/SporthalSection";
import ScheduleSection from "./ScheduleSection/ScheduleSection";
import KalenderSection from "./KalenderSection/KalenderSection";

import paralax from "./homepageParalax.jpg";
import LobPage from "../../components/LobPage/LobPage";
import { Button, WithTheme, withTheme } from "@material-ui/core";
import useT from "../../components/CMSLobPage/useT";
import Loading from "../../components/CMSLobPage/Loading";
import T from "../../components/CMSLobPage/T";
import Warning from "./Warning";

interface IHomePage { }
type Props = IHomePage & WithStyles<typeof landingPageStyle> & WithTheme;

const HomePage: React.FC<Props> = ({ classes }) => {
  const { t, isLoaded }  = useT('home');
  if (!isLoaded) {
    return <Loading />;
  }
  return (
    <LobPage
      paralax={paralax}
      header={{height: 400 }}
      paralaxContent={(
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}><T t={t} k='paralax-title' /></h1>
              <h4>
                <T t={t} k='paralax-description' />
              </h4>
              <br />
              <Link to="inschrijven">
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  <FontAwesomeIcon icon={faPlay} />
                  &nbsp;
                  <T t={t} k={'paralax-button'} />
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
          <Warning />
        </div>
      )}
    >
      <>
        <TrainingSection t={t} />
        <ScheduleSection t={t} />
        <KalenderSection />
        <SporthalSection t={t} />
        <ContactSection t={t} />
      </>
    </LobPage>
  );
};

export default withTheme(withStyles(landingPageStyle)(HomePage));
