import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/styles";
import { useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import FullCalendar from '@fullcalendar/react'
import { EventApi } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import { EventSourceInput } from '@fullcalendar/core/structs/event-source';
import nlLocale from '@fullcalendar/core/locales/nl';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

import events from './lob-calendar.json';


import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import './fullcalendar-theme.sass';

// core components
// import GridContainer from "../../../components/Grid/GridContainµer";
// import GridItem from "../../../components/Grid/GridItem";

import kalenderSectionStyle from "./kalenderSectionStyle";
const GOOGLE_CALENDAR_API_KEY = 'AIzaSyCS6v7UpKqIZLs-KywPi_JebPnPkqiGl-I';

const clickEvent = (info: EventApi) => {
    if(info.url) {
        window.open(info.url, "_blank");
    }
}


const ResponsiveCalendar : React.FC = () => {
    const theme = useTheme();
    // const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    // useMediaQuery triggers twice with different value, check window width ourselves
    const isMdUp = window.innerWidth >= theme.breakpoints.values.md;
    const eventSources: EventSourceInput[] = [
        {
          className: 'competitie-event',
          events, 
          backgroundColor: theme.palette.secondary.main,
        },
        {
            googleCalendarId: 'lob.kalender@gmail.com',
            className: 'club-event',
            backgroundColor: theme.palette.primary.main,
        },
        {
            googleCalendarId: 'feestdagenbelgie@gmail.com',
            className: 'vakantie-event',
            backgroundColor: theme.palette.error.main,
        },
    ];

    return (
        <FullCalendar
            defaultView={isMdUp ? "dayGridMonth" : "listWeek"}
            googleCalendarApiKey={GOOGLE_CALENDAR_API_KEY}
            plugins={[ dayGridPlugin, listPlugin, googleCalendarPlugin ]}
            eventSources={eventSources}
            eventClick={(data) => { data.jsEvent.preventDefault(); clickEvent(data.event); return false; }}
            locale={nlLocale}
            header={isMdUp ? {
                left: 'prev,today,next',
                center: 'title',
                right: 'dayGridMonth,listWeek',
            } : {
                left: '',
                center: 'prev,today,next',
                right: '',
            }}
        />
    );
}



interface IKalenderSection { }
type Props = IKalenderSection & WithStyles<typeof kalenderSectionStyle>;
class KalenderSection extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <ResponsiveCalendar />
      </div>
    );
  }
}

export default withStyles(kalenderSectionStyle)(KalenderSection);
