import { createTheme } from "@material-ui/core";

const lobTheme = createTheme({
    palette: {
        primary: {
            main: '#004b87',
        },
        secondary: {
            main: '#fed204',
        },
    },
});

export default lobTheme;