/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShuttlecock, faCommentSmile } from "@fortawesome/pro-regular-svg-icons";
import { faBeer } from "@fortawesome/free-solid-svg-icons";

// core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import InfoArea from "../../../components/InfoArea/InfoArea";

import productStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/productStyle";
import { TFN } from "../../../components/CMSLobPage/useT";
import T from "../../../components/CMSLobPage/T";

interface ITrainingSection {
  t: TFN,
}
type Props = ITrainingSection & WithStyles<typeof productStyle>;

const TrainingSection: React.FC<Props> = ({ classes, t }) => {
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}><T t={t} k="section1-title"/></h2>
          <h5 className={classes.description}><T t={t} k="section1-description" /></h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title={t('section1-chat-title').value}
              description={<T t={t} k="section1-chat-description" />}
              icon={<FontAwesomeIcon icon={faCommentSmile} color="success" size="4x" />}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title={t('section1-play-title').value}
              description={<T t={t} k="section1-play-description" />}
              icon={<FontAwesomeIcon icon={faShuttlecock} color="success" size="4x" />}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title={t('section1-drink-title').value}
              description={<T t={t} k="section1-drink-description" />}
              icon={<FontAwesomeIcon icon={faBeer} color="success" size="4x" />}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default withStyles(productStyle)(TrainingSection);
