/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/styles";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import teamPageStyle from "./teamPageStyle";
import TeamMember from './TeamMember';

import useCrews from "./useCrews";
import Loading from "../../components/CMSLobPage/Loading";

interface ITeamSection { }
type Props = ITeamSection & WithStyles<typeof teamPageStyle>;

const TeamPage: React.FC<Props> = ({ classes }) => {
  const { crews, error, isLoading } = useCrews();
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <h2 className={classes.title}>Oeps! Er is iets misgelopen</h2>;
  }

  const { bestuur, wedstrijdleiders, trainers } = crews;
  return (
    <>
      <div className={classes.section}>
        <h2 className={classes.title}>Het bestuur</h2>
        <div>
          <GridContainer>
            {bestuur.map((member) => (
              <GridItem xs={12} sm={12} md={4} key={member.name}>
                <TeamMember {...member} />
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
      <div className={classes.section}>
        <h2 className={classes.title}>Trainers</h2>
        <div>
          <GridContainer>
            {trainers.map((trainer) => (
              <GridItem xs={12} sm={12} md={4} key={trainer.name}>
                <TeamMember {...trainer} />
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>

      <div className={classes.section}>
        <h2 className={classes.title}>Wedstrijdleiders</h2>
        <div>
          <GridContainer>
            {wedstrijdleiders.map((trainer) => (
              <GridItem xs={12} sm={12} md={4} key={trainer.name}>
                <TeamMember {...trainer} />
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
    </>
  );
}

export default withStyles(teamPageStyle)(TeamPage);
