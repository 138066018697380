import React from 'react';
import classNames from "classnames";
import { withStyles, WithStyles } from '@material-ui/core/styles';

// import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";

import teamPageStyle from "./teamPageStyle";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import racket from './racket.jpg';

export interface ITeamMemberProps {
    name: string;
    description?: JSX.Element | string;
    function?: string;
    avatar?: string;
    twitter?: string;
    instagram?: string;
    facebook?: string;
    email?: string;
    phone?: string;
}

type Props = ITeamMemberProps & WithStyles<typeof teamPageStyle>;

const TeamMember: React.FC<Props> = (props) => {
    const { classes, avatar, name, description, function: fn, twitter, instagram, facebook, email, phone } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid,
        classes.avatar,
    );
    return (
        <Card plain>
            <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <div className={imageClasses} style={{backgroundImage: `url(${avatar ?? racket})`}} />
            </GridItem>
            <h4 className={classes.cardTitle}>
                {name}
                {fn && (
                    <>
                        <br />
                        <small className={classes.smallTitle}>{fn}</small>
                    </>
                )}
            </h4>
            {description && (
                <CardBody>
                    <p className={classes.description}>{description}</p>
                </CardBody>
            )}
            <CardFooter className={classes.justifyCenter}>
                {twitter && (
                    <Button
                        justIcon
                        color="transparent"
                        className={classes.margin5}
                    >
                        <i className={classes.socials + " fab fa-twitter"} />
                    </Button>
                )}
                {instagram && (
                    <Button
                        justIcon
                        color="transparent"
                        className={classes.margin5}
                    >
                      <FontAwesomeIcon className={classes.socials} icon={faInstagram} />
                    </Button>
                )}
                {facebook && (
                    <Button
                        justIcon
                        color="transparent"
                        className={classes.margin5}
                        href={`https://www.facebook.com/${facebook}`}
                        target="_blank"
                    >
                      <FontAwesomeIcon className={classes.socials} icon={faFacebook} />
                    </Button>
                )}
                {phone && (
                    <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                    href={`tel:${phone}`}
                    >
                      <FontAwesomeIcon className={classes.socials} icon={faWhatsapp} />
                    </Button>
                )}
                {email && (
                    <Button
                        justIcon
                        color="transparent"
                        className={classes.margin5}
                        href={`mailto:${email}`}
                    >
                      <FontAwesomeIcon className={classes.socials} icon={faEnvelope} />
                    </Button>
                )}
            </CardFooter>
        </Card>
    );
};

export default withStyles(teamPageStyle)(TeamMember);