import React from 'react';
import { useLobAPI } from "./useLobAPI";
import { TranslationEntry } from '../../lib/LobAPI';

const placeholderTranslationEntry = (key: string, defaultValue?: string): TranslationEntry => ({ key, type: 'text', value: defaultValue ?? '...'});

export type TFN = typeof placeholderTranslationEntry;

function useT(group: string): { t: TFN, isLoaded: boolean } {
  const api = useLobAPI();
  const [t, setT] = React.useState<TFN>(() => placeholderTranslationEntry);
  const [isLoaded, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    api.getTranslationByGroup(group).then((translation) => {
      setT(() => ((key: string) => {
        return translation.entries[key] ?? placeholderTranslationEntry(key);        
      }));
    }).catch((e) => {
      console.error('Failed getting translations', e);
    }).finally(() => {
      setIsLoaded(true);
    });
  }, [group, api]);

  return { t, isLoaded };
}

export default useT;
