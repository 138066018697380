import LobAPI from "../../lib/LobAPI";

const url = process.env.REACT_APP_LOB_API_URL;
const api = new LobAPI(url);

export function useLobAPI() {
    return api;
    // return mock;
}

// import { MockLobAPI } from "../../lib/MockLobAPI";

// const mock = new MockLobAPI();

// export function useLobAPI() {
//     return mock;
// }
