import React from "react";

import LobPage from "../../components/LobPage/LobPage";

import contactParalax from './contactParalax.jpg';

interface IContactPage { }
type Props = IContactPage;

class ContactPage extends React.Component<Props> {
  render() {
    // const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
      <LobPage paralax={contactParalax} small={true}>
        <iframe
            title="Contact form on google forms"
            src="https://docs.google.com/forms/d/e/1FAIpQLSe8g3cDganOIc9Uvp3l-VXwrX12xvKvpPDxmdSvmKikDkhudw/viewform?embedded=true"
            style={{
              width: '100%',
              height: 1000,
              margin: 0,
              border: 0,
            }} />
      </LobPage>
    );
  }
}

export default ContactPage;
